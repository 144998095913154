import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';

export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_title_color',
        label: 'General text color',
        type: 'color',
        default: theme.palette.text.primary,
      },
      {
        name: 'theme_component_title_title_color',
        label: 'Title text color',
        type: 'color',
        default: theme.palette.text.primary,
      },
      {
        name: 'theme_component_title_link_hover_color',
        label: 'Link text hover color',
        type: 'color',
        default: theme.palette.action.hover,
      },
      {
        name: 'theme_component_title_link_active_color',
        label: 'Link text active color',
        type: 'color',
        default: theme.palette.action.active,
      },
      // {
      //   name: 'theme_component_title_padding',
      //   label: 'Padding',
      //   type: 'text',
      //   default: theme.spacing(1, 2),
      //   helperText: 'Define as top right left bottom; e.g.: 2px 10px 5px 10px',
      // },
      {
        name: 'theme_component_title_lg_padding',
        label: 'Padding for large screens',
        type: 'text',
        default: theme.spacing(1, 2),
        helperText: 'Define as top right left bottom; e.g.: 2px 10px 5px 10px',
      },
      // {
      //   name: 'theme_component_title_justify_content',
      //   label: 'Justify content',
      //   type: 'select',
      //   default: 'space-between',
      //   options: ['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly'],
      // },
      // {
      //   name: 'theme_component_title_align_items',
      //   label: 'Align items',
      //   type: 'select',
      //   default: 'center',
      //   options: ['flex-start', 'flex-end', 'center', 'baseline', 'stretch'],
      // },
      {
        name: 'theme_component_title_border_bottom_width',
        label: 'Border bottom width',
        type: 'number',
        endAdornment: 'px',
        default: 1,
      },
      {
        name: 'theme_component_title_border_bottom_color',
        label: 'Border bottom color',
        type: 'color',
        default: theme.palette.divider,
      },
    ],
    configuration: [],
  });
}