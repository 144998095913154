import { CheckOutlined } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next-translate-routes';
import { useAuthenticationContext } from '../../contexts/AuthenticationContext';

const ModalContainer = styled(Modal)(() => ({
  background: 'linear-gradient(0deg, #000000 0%, #191040b5 30%, rgba(0,212,255,0) 100%)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  cursor: 'default',
  button: {
    transition: '0.01s',
    '&:hover': {
      background: '#3352fe',
      outline: '1px solid white',
    },
  },
}));

const HeaderContainer = styled('div')(() => ({
  fontSize: '54px',
  fontWeight: '600',
  textTransform: 'uppercase',
  p: {
    color: 'white',
  },
  '@media screen and (max-width: 800px)': {
    fontSize: '24px',
  },
}));

const HeaderSubText = styled('div')(() => ({
  color: 'orange',
  marginBottom: '16px',
}));

const LoginButtonContainer = styled('div')(() => ({
  position: 'absolute',
  right: '16px',
  top: '16px',
  display: 'block',
  '@media screen and (max-width: 800px)': {
    display: 'none',
  },
}));

const PrimaryButton = styled(Button)(() => ({
  background: '#3352fe',
  padding: '8px 24px',
  color: 'white',
  fontSize: '18px',
}));

const StartButtonContainer = styled(Button)(() => ({
  background: '#3352fe',
  padding: '12px 32px',
  color: 'white',
  fontSize: '18px',
  '@media screen and (max-width: 800px)': {
    maxWidth: 'calc(100% - 24px)',
  },
}));

const BoxContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  paddingBottom: 3,
  width: '80vw',
  height: '80vh',
  outline: 'none',
  paddingTop: 2,
  background: 'url("/images/settings-bg.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  borderBottom: '2px solid #3352fe',
  '@media screen and (max-width: 800px)': {
    backgroundSize: 'auto 100%',
  },
}));

const ListContainer = styled('div')(() => ({}));

const ListItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '8px 0',
  color: 'white',
  svg: {
    marginRight: '8px',
    height: '32px',
    width: '32px',
  },
}));

const TextUnderline = styled('a')(() => ({
  cursor: 'pointer',
  color: 'white',
  textDecoration: 'underline',
  '&:hover': {
    color: '#fafafa',
    textDecorationThickness: '2px',
  },
}));

export const SubscriptionModal = () => {
  const [openState, setOpenState] = React.useState<boolean>(true);
  const { userToken } = useAuthenticationContext();

  const handleClose = () => {
    setOpenState(false);
  };

  const router = useRouter();

  const redirectAzcConnect = async () => {
    router.push('/subscription/auth');
  };

  return openState ? (
    <ModalContainer
      open={!userToken && openState}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxContainer
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          px: 4,
        }}
      >
        <LoginButtonContainer>
          <PrimaryButton color="primary" onClick={redirectAzcConnect}>
            Login
          </PrimaryButton>
        </LoginButtonContainer>
        <HeaderContainer>
          <p style={{ marginTop: '24px', marginBottom: 0 }}> Play all of huz.</p>
          <p style={{ margin: '8px 0' }}>Try us, cancel at anytime </p>
        </HeaderContainer>
        <div>
          <HeaderSubText>Enjoy and play unlimited online games for only $4.99/month</HeaderSubText>
        </div>
        <ListContainer>
          {[
            'No Advirtesement',
            'Unlimited gameplays',
            'Cancel at anytime',
            'Play on any device',
            'Children Account',
          ].map((i: any) => (
            <ListItem key={i}>
              <CheckOutlined style={{ color: 'green' }} />
              {i}
            </ListItem>
          ))}
        </ListContainer>
        <div style={{ position: 'absolute', bottom: '32px' }}>
          <div style={{ marginBottom: '12px', color: 'white' }}>
            HUZ is a subscription service. To see Terms and Conditions{' '}
            <TextUnderline href="https://static.gamedistribution.com/terms/both.html">click here.</TextUnderline>
          </div>
          <StartButtonContainer color="primary" onClick={redirectAzcConnect}>
            Start Your Game Adventure
          </StartButtonContainer>
          <div style={{ marginTop: '12px', color: 'white' }}>
            You can cancel anytime, <TextUnderline onClick={handleClose}>or try later</TextUnderline>
          </div>
        </div>
      </BoxContainer>
    </ModalContainer>
  ) : (
    <></>
  );
};
